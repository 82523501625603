import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../base/base-service';
import { environment } from '../../environments/environment';
import { BaseEntity } from 'src/app/base/base-entity';
import { Observable, forkJoin, of } from 'rxjs';
import { Result } from '../base/result';
import { sessionContext, TenantInfo } from './session-details';
import { PermissionResourceMapping } from '../shared/models/authentication/PermissionResourceMapping';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { LogService } from '../logger/log.service';
import { retryWithDelay } from '../base/common';
import { UserRecord } from '../iaas-optimization/iaas-optimization';
import { isNotNull } from '../base/utils';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseService<BaseEntity, 'entity'> {
  MAX_RETRY_ATTEMPTS: number = 1;
  DELAY_BETWEEN_RETRY: number = 1000;
  TANGOE_DOMAIN: string = 'tangoe';
  saasPartnerLogout: boolean = false;
  logoutEvent: boolean = false;
  tenantTheme$: Observable<Result<'tenant_product_configuration', any[]>>;
  constructor(protected http: HttpClient, protected logService: LogService) {
    super(http);
  }

  getServiceUrl(): string {
    return null;
  }

  getBaseUrl(): string {
    return null;
  }

  getMetaData(): any {}

  // TODO: remove support for 'string' as resource param
  hasAccess(resource: string | PermissionResourceMapping): boolean {
    if (resource === 'ALWAYS_ALLOW') {
      return true;
    }

    const resourceToMatch = `{${resource}}.VIEW`;

    return sessionContext.permissions.includes(resourceToMatch);
  }

  switchTenant(tenantInfo: TenantInfo): Observable<any> {
    const jsonbody = {
      tenant_row_id: String(tenantInfo.tenantRowId),
      tenant_short_name: tenantInfo.shortName,
    };

    const urlBrowser = environment.applicationTenantSwitchUrl;
    const callBrowser: Observable<boolean> = super.simplePost(urlBrowser, jsonbody).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        this.logService.error(
          `SwitchTenant: Error ${err.status} at switch_tenant.htm while trying to switch tenant to ${tenantInfo.shortName}`,
          err
        );
        return of(false);
      })
    );

    const url = environment.securityServiceUrl + '/session/switchTenant';
    const callEndpoint: Observable<boolean> = super.simplePost(url, jsonbody).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        this.logService.error(
          `SwitchTenant: Error ${err.status} at switchTenant while trying to switch tenant to ${tenantInfo.shortName}`,
          err
        );
        return of(false);
      })
    );
    this.sisenseLogout();
    return forkJoin([callBrowser, callEndpoint]).pipe(
      map((results: boolean[]) => {
        const r1: boolean = results[0];
        const r2: boolean = results[1];
        if (r1 === false || r2 === false) {
          return false;
        }
        return results;
      })
    );
  }

  loggedInUser(): string {
    return sessionContext.userInfo.userIdentifier;
  }

  loggedInUserTenants(): Observable<Result<'tenant', any[]>> {
    const headers = this.buildHeaders();
    const url = environment.securityServiceUrl + `/session/accessibleTenants`;
    const params: any = {};

    return this.http
      .get<Result<'tenant', any>>(url, {
        headers,
        params,
      })
      .pipe(retryWithDelay<'tenant'>(this.DELAY_BETWEEN_RETRY, this.MAX_RETRY_ATTEMPTS));
  }

  loggedInUserDetails(): Observable<Result<'user_details', any>> {
    const headers = this.buildHeaders();
    const url = environment.securityServiceUrl + '/session/loggedInUser';
    const params: any = {};

    return this.http
      .get<Result<'user_details', any>>(url, {
        headers,
        params,
      })
      .pipe(retryWithDelay<'user_details'>(this.DELAY_BETWEEN_RETRY, this.MAX_RETRY_ATTEMPTS));
  }

  loggedInUserTenantTheme(): Observable<Result<'tenant_product_configuration', any[]>> {
    if (!this.tenantTheme$) {
      const headers = this.buildHeaders();
      const url = environment.extEnterpriseDataUrl + `/tenantProductConfig`;
      const params: any = {};

      this.tenantTheme$ = this.http
        .get<Result<'tenant_product_configuration', any>>(url, { headers, params })
        .pipe(
          shareReplay(1),
          catchError(error => {
            this.tenantTheme$ = null;
            return of(null);
          })
        );
    }
    return this.tenantTheme$;
  }

  loggedInUserApplications(): Observable<Result<'user_applications', any[]> | any[]> {
    const headers = this.buildHeaders();
    const url = environment.securityServiceUrl + `/session/loggedInUserApplications`;
    const params: any = {};

    return this.http
      .get<Result<'user_applications', any[]>>(url, {
        headers,
        params,
      })
      .pipe(
        retryWithDelay<'user_applications'>(this.DELAY_BETWEEN_RETRY, this.MAX_RETRY_ATTEMPTS),
        catchError((err: HttpErrorResponse) => {
          this.logService.error(`UserApplications: Error ${err.status} when trying to get the user applications.`, err);
          return Observable.of([] as any[]);
        })
      );
  }

  loggedInUserPermissions(): Observable<Result<'privilege_details', any[]>> {
    const headers = this.buildHeaders();
    const url = environment.securityServiceUrl + '/session/loggedInUserAccessDetails';
    const params: any = {};

    return this.http
      .get<Result<'privilege_details', any[]>>(url, {
        headers,
        params,
      })
      .pipe(retryWithDelay<'privilege_details'>(this.DELAY_BETWEEN_RETRY, this.MAX_RETRY_ATTEMPTS));
  }

  logoutUser(): Observable<any> {
    const url = environment.securityServiceUrl + '/session/logout';

    return super.simplePost(url, {});
  }

  logoutIaasToken(): Observable<any> {
    const url = environment.iaasOptimizationUrl + '/v2/logout';
    const params: any = {};
    params.client_id = sessionContext.userInfo.cloudadmin_client_id;
    params.access_token = localStorage.getItem('cloudadminAccessToken');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(url, params, {
      headers,
    });
  }

  invalidateSession(): Observable<any> {
    return this.http.get(environment.applicationLogoutUrl, {});
  }

  getRoleNameBasedOnUser(selectedUserRecord) {
    if (selectedUserRecord.roles.length) {
      if (selectedUserRecord.roles.find(o => o.name === 'IaaS Optimization Admin')) {
        return 'IaaS Optimization Admin';
      } else if (selectedUserRecord.roles.find(o => o.name === 'IaaS Optimization User')) {
        return 'IaaS Optimization User';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  sisenseLogout() {
    if (document.querySelector('iframe')) {
      document.querySelector('iframe').contentWindow.postMessage({ logout: true }, environment.sisenseUrl);
    }
  }

  getDefaultLandingPath(userRole) {
    let redirectPath: string;
    // Check for deep link
    const quickLinkPath = this.validateQuickLinkPath();
    if (quickLinkPath) {
      return quickLinkPath;
    }
    // Check for Loggedin user role
    const isReportOrAdminRole = this.getReportingRole();
    if (isReportOrAdminRole) {
      redirectPath = 'dashboard';
    } else {
      const currentUserRole = userRole[0].name;
      switch (currentUserRole) {
        case 'Customer Invoice Processing':
        case 'Tangoe Invoice Processing': {
          redirectPath = 'invoices';
          break;
        }
        case 'Customer Contracts': {
          redirectPath = 'contracts';
          break;
        }
        case 'Customer Budgeting': {
          redirectPath = 'budget-dashboard';
          break;
        }
        case 'IaaS Optimization Admin':
        case 'IaaS Optimization User': {
          redirectPath = 'iaas';
          break;
        }
        case 'SaaS Customer Admin':
        case 'SaaS Finance Admin':
        case 'SaaS IT Admin':
        case 'SaaS Owner':
        case 'SaaS Procurement Admin':
        case 'SaaS Tangoe Admin':
        case 'SaaS Viewer': {
          redirectPath = 'saas-external-resource/saas-overview';
          break;
        }
        case 'SaaS Integration Admin': {
          redirectPath = 'saas-external-resource/saas-integrations';
          break;
        }
        default: {
          redirectPath = '';
          break;
        }
      }
    }
    return redirectPath;
  }

  getReportingRole() {
    if (sessionContext.roleInfo) {
      return sessionContext.roleInfo.some((role: any) =>
        [
          'Report Viewer',
          'Report Designer',
          'Tangoe Admin',
          'Tangoe Cloud Admin',
          'Customer Cloud Admin',
          'Tangoe Solution Architect',
          'Customer Admin Read Only',
        ].includes(role.name)
      );
    } else {
      return false;
    }
  }

  validateQuickLinkPath() {
    let redirectPath: string;
    // Check path in sessionStorage for quick-link
    redirectPath = sessionStorage.getItem('redirectPathName');
    return redirectPath;
  }

  handleUserDetails(userDetails): void {
    sessionContext.userInfo.userIdentifier = userDetails.user_details.user.identifier;
    sessionContext.userInfo.userRowId = userDetails.user_details.user.id;
    sessionContext.userInfo.authStrategy = userDetails.user_details.auth_strategy;
    sessionContext.userInfo.cloudadmin_client_id = userDetails.user_details.iaasoptimization_client_id;
    sessionContext.roleInfo = userDetails.user_details.roles;

    sessionContext.userInfo.emailAddress =
      userDetails.user_details.employee.email || userDetails.user_details.user.identifier;

    const firstName =
      (userDetails && userDetails.user_details.employee.first_name) ||
      this.parseEmailAndGetName(sessionContext.userInfo.emailAddress, 'FIRST_NAME');
    const lastName =
      (userDetails && userDetails.user_details.employee.last_name) ||
      this.parseEmailAndGetName(sessionContext.userInfo.emailAddress, 'LAST_NAME');

    sessionContext.userInfo.presentationName = (firstName + (lastName ? ' ' + lastName : '')).toUpperCase();
    sessionContext.userInfo.nameInitials = (firstName.charAt(0) + (lastName ? lastName.charAt(0) : '')).toUpperCase();

    sessionContext.userInfo.preferredFolder = userDetails.user_details.user.dashboard_folder_name;
    sessionContext.userInfo.preferredDashboard = userDetails.user_details.user.dashboard_report_name;

    sessionContext.tenantInfo.shortName = userDetails.user_details.tenant.short_name;
    sessionContext.tenantInfo.tenantName = userDetails.user_details.tenant.name;
    sessionContext.tenantInfo.tenantRowId = userDetails.user_details.tenant.id;
    sessionContext.tenantInfo.budgetEntity = userDetails.user_details.tenant.budget_entity;
    sessionContext.tenantInfo.isIaasOptimized = userDetails.user_details.tenant.is_iaas_optimized;
    sessionContext.tenantInfo.isSaaS = userDetails.user_details.tenant.is_saa_s;
    sessionContext.partnerInfo.partnerTenantId = userDetails.user_details.partner.partner_tenant_id;
    sessionContext.partnerInfo.partnerName = userDetails.user_details.partner.partner_name;
    sessionContext.userInfo.auth_token = userDetails.user_details.auth_token;
    sessionContext.loginSuccess = true;
    environment.x_tngo_tenant = sessionContext.tenantInfo.shortName;
    environment.x_tngo_user = sessionContext.userInfo.userIdentifier;
  }

  parseEmailAndGetName(email: string, part: string): string {
    const name: string = email.substring(0, email.lastIndexOf('@'));
    const nameParts: string[] = name.split('.');

    if (part === 'FIRST_NAME') {
      if (nameParts.length > 0) {
        return nameParts[0];
      }
    }

    if (part === 'LAST_NAME') {
      if (nameParts.length > 1) {
        return nameParts[1];
      }
    }

    return '';
  }

  getUserDetails() {
    const userDetails = new UserRecord();
    const fullName = sessionContext.userInfo.presentationName.split(' ');
    const tenantList = sessionContext.tenantList;
    const tenantInfo = sessionContext.tenantInfo;
    const allTenants = [];

    const userRole = this.getRoleNameBasedOnUser({ roles: sessionContext.roleInfo });

    for (const tenant of tenantList) {
      allTenants.push({ short_name: tenant.shortName, id: tenant.tenantRowId, selected: false });
    }
    allTenants.push({ short_name: tenantInfo.shortName, id: tenantInfo.tenantRowId, selected: true, role: userRole });

    userDetails.email = sessionContext.userInfo.emailAddress;
    userDetails.user_id = sessionContext.userInfo.userRowId;
    userDetails.first_name = fullName[0] ? fullName[0] : '';
    userDetails.last_name = fullName[1] ? fullName[1] : '';
    userDetails.tenants = allTenants;

    return userDetails;
  }

  getSaasResourceList() {
    const permission = sessionContext.permissions.filter(s => s.includes(PermissionResourceMapping.SaasOptimization));
    const userRole = [];
    if (permission.length) {
      for (const assiignedRole of permission) {
        const resource: any = this.getResource(assiignedRole);
        if (resource.length) {
          if (resource.some((resourceName: any) => ['SAAS.ADMIN'].includes(resourceName))) {
            userRole.push('SAAS.ADMIN');
          }
          if (resource.some((resourceName: any) => ['SAAS.VIEWER'].includes(resourceName))) {
            userRole.push('SAAS.VIEWER');
          }
          if (resource.some((resourceName: any) => ['SAAS.OWNER'].includes(resourceName))) {
            userRole.push('SAAS.OWNER');
          }
          if (resource.some((resourceName: any) => ['SAAS.CUSTOMER'].includes(resourceName))) {
            userRole.push('SAAS.CUSTOMER');
          }
          if (resource.some((resourceName: any) => ['SAAS.INTEGRATION_ADMIN'].includes(resourceName))) {
            userRole.push('SAAS.INTEGRATION_ADMIN');
          }
          if (resource.some((resourceName: any) => ['SAAS.IT_ADMIN'].includes(resourceName))) {
            userRole.push('SAAS.IT_ADMIN');
          }
          if (resource.some((resourceName: any) => ['SAAS.FINANCE_ADMIN'].includes(resourceName))) {
            userRole.push('SAAS.FINANCE_ADMIN');
          }
          if (resource.some((resourceName: any) => ['SAAS.PROCUREMENT_ADMIN'].includes(resourceName))) {
            userRole.push('SAAS.PROCUREMENT_ADMIN');
          }
        }
      }
    }

    return userRole;
  }

  getResourceList() {
    const permission = sessionContext.permissions.filter(s => s.includes(PermissionResourceMapping.IaasOptimization));
    const resource: any = this.getResource(permission);

    if (resource.length) {
      if (resource.some((resourceName: any) => ['IAASOPTIMIZATION.ADMIN'].includes(resourceName))) {
        return 'IAASOPTIMIZATION.ADMIN';
      } else if (resource.some((resourceName: any) => ['IAASOPTIMIZATION.USER'].includes(resourceName))) {
        return 'IAASOPTIMIZATION.USER';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getResource(permission) {
    const resource = [];
    const permissionMap = sessionContext.permissions.filter(s => permission.includes(s));
    for (const resourceItem of permissionMap) {
      const filterResource = resourceItem
        .split('{')
        .join('')
        .split('}')[0];
      if (filterResource) {
        resource.push(filterResource);
      }
    }
    return resource;
  }

  handleTenantShortName() {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    let tenantShortName = urlParams.get('tenantShortName');
    const tenantSubDomain = urlParams.get('tenantSubDomain');
    const loginUrl = new URL(environment.ssoLoginUrl);
    if (
      isNotNull(tenantSubDomain) &&
      tenantSubDomain !== this.TANGOE_DOMAIN &&
      loginUrl.hostname.split('.')[0] === this.TANGOE_DOMAIN
    ) {
      environment.ssoLoginUrl = environment.ssoLoginUrl.replace(this.TANGOE_DOMAIN, tenantSubDomain);
      environment.ssoUrl = environment.ssoUrl.replace(this.TANGOE_DOMAIN, tenantSubDomain);
    }
    if (tenantShortName) {
      sessionStorage.setItem('tenantShortName', tenantShortName);
    } else {
      tenantShortName = sessionStorage.getItem('tenantShortName');
    }
    return tenantShortName;
  }

  removeTenantFromSession() {
    const tenantShortName = sessionStorage.getItem('tenantShortName');
    if (tenantShortName) {
      sessionStorage.removeItem('tenantShortName');
    }
  }

  removeQuickLinkPathFromSession() {
    const redirectPath = sessionStorage.getItem('redirectPathName');
    if (redirectPath) {
      sessionStorage.removeItem('redirectPathName');
    }
  }

  // Build Info
  buildInfo(): Observable<any> {
    const headers = this.buildHeaders();
    return this.http.get(environment.oldUiBaseUrl + '/buildInfo', { headers });
  }
}
