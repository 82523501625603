import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { SaaSMenuInfo, sessionContext } from '../authentication/session-details';

@Injectable({
  providedIn: 'root',
})
export class SaasMenuProvider {
  currentResource = [];
  constructor(private authenticationService: AuthenticationService) {}
  getSaasMenusBasedOnRole() {
    if (!sessionContext.tenantInfo.isSaaS) {
      return [];
    }
    this.currentResource = this.getPermissionResourceForSaas();
    const saasMenueInfo: SaaSMenuInfo = new SaaSMenuInfo();
    let saasChildMenu = [];
    if (
      this.currentResource.includes('SAAS.ADMIN') ||
      this.currentResource.includes('SAAS.VIEWER') ||
      this.currentResource.includes('SAAS.OWNER') ||
      this.currentResource.includes('SAAS.CUSTOMER')
    ) {
      this.currentResource = this.currentResource[0];
      saasMenueInfo.admin = true;
      saasChildMenu = this.getSaaSMenu(saasMenueInfo);
    } else {
      if (this.currentResource.includes('SAAS.INTEGRATION_ADMIN')) {
        saasMenueInfo.integration_admin = true;
      }
      if (this.currentResource.includes('SAAS.IT_ADMIN')) {
        saasMenueInfo.it_admin = true;
      }
      if (this.currentResource.includes('SAAS.FINANCE_ADMIN')) {
        saasMenueInfo.finance_admin = true;
      }
      if (this.currentResource.includes('SAAS.PROCUREMENT_ADMIN')) {
        saasMenueInfo.procurement_admin = true;
      }
      this.currentResource = this.currentResource[0];
      saasChildMenu = this.getSaaSMenu(saasMenueInfo);
    }
    return saasChildMenu;
  }

  getSaaSMenu(currentRole) {
    let saasMenu = [];
    const overviewRouteObject = {
      routeTitle: 'Overview',
      route: '/saas-external-resource/saas-overview',
      resource: this.currentResource,
    };
    const workflowRouteObject = {
      routeTitle: 'Workflows',
      description: 'Workflows',
      subRoutes: [
        {
          routeTitle: 'Onboarding',
          route: '/saas-external-resource/saas-workflows/onboarding',
          resource: this.currentResource,
        },
        {
          routeTitle: 'Offboarding',
          route: '/saas-external-resource/saas-workflows/offboarding',
          resource: this.currentResource,
        },
        {
          routeTitle: 'App Requisition',
          route: '/saas-external-resource/saas-workflows/apprequisition',
          resource: this.currentResource,
        },
      ],
    };
    const licensesRouteObject = {
      routeTitle: 'Licenses',
      description: 'Licenses',
      subRoutes: [
        {
          routeTitle: 'Licenses',
          route: '/saas-external-resource/saas-licenses/licenses',
          resource: this.currentResource,
        },
        {
          routeTitle: 'Renewals',
          route: '/saas-external-resource/saas-licenses/renewals',
          resource: this.currentResource,
        },
        {
          routeTitle: 'Vendors',
          route: '/saas-external-resource/saas-licenses/vendors',
          resource: this.currentResource,
        },
      ],
    };
    const optimizationRouteObject = {
      routeTitle: 'Optimization',
      route: '/saas-external-resource/saas-optimization/summary',
      resource: this.currentResource,
    };
    const transactionsRouteObject = {
      routeTitle: 'Transactions',
      route: '/saas-external-resource/saas-transactions',
      resource: this.currentResource,
    };
    const applicationsRouteObject = {
      routeTitle: 'Applications',
      route: '/saas-external-resource/saas-applications',
      resource: this.currentResource,
    };
    const usersRouteObject = {
      routeTitle: 'Users',
      route: '/saas-external-resource/saas-users',
      resource: this.currentResource,
    };
    const departmentsRouteObject = {
      routeTitle: 'Departments',
      route: '/saas-external-resource/saas-departments',
      resource: this.currentResource,
    };
    const securityRouteObject = {
      routeTitle: 'Security',
      route: '/saas-external-resource/saas-security',
      resource: this.currentResource,
    };
    const reportsRouteObject = {
      routeTitle: 'Reports',
      route: '/saas-external-resource/saas-reports',
      resource: this.currentResource,
    };
    const sourcesRouteObject = {
      routeTitle: 'Sources',
      description: 'Sources',
      subRoutes: [
        {
          routeTitle: 'Integrations',
          route: '/saas-external-resource/saas-integrations',
          resource: this.currentResource,
        },
        {
          routeTitle: 'Agents',
          route: '/saas-external-resource/saas-agents',
          resource: this.currentResource,
        },
      ],
    };
    const settingsRouteObject = {
      routeTitle: 'Settings',
      description: 'Settings',
      route: '/saas-external-resource/saas-settings',
      resource: this.currentResource,
    };
    const auditLogsRouteObject = {
      routeTitle: 'Audit Logs',
      route: '/saas-external-resource/saas-auditlogs',
      resource: this.currentResource,
    };
    saasMenu = [
      currentRole.admin || currentRole.it_admin || currentRole.finance_admin || currentRole.procurement_admin
        ? { ...overviewRouteObject }
        : null,
      currentRole.admin || currentRole.it_admin ? { ...applicationsRouteObject } : null,
      currentRole.admin || currentRole.it_admin ? { ...usersRouteObject } : null,
      currentRole.admin || currentRole.it_admin ? { ...departmentsRouteObject } : null,
      currentRole.admin || currentRole.finance_admin || currentRole.procurement_admin
        ? { ...transactionsRouteObject }
        : null,
      currentRole.admin || currentRole.it_admin || currentRole.procurement_admin ? { ...licensesRouteObject } : null,
      currentRole.admin || currentRole.it_admin || currentRole.finance_admin || currentRole.procurement_admin
        ? { ...optimizationRouteObject }
        : null,
      currentRole.admin || currentRole.it_admin ? { ...securityRouteObject } : null,
      currentRole.admin || currentRole.it_admin ? { ...reportsRouteObject } : null,
      currentRole.admin || currentRole.integration_admin || currentRole.it_admin ? { ...sourcesRouteObject } : null,
      currentRole.admin || currentRole.integration_admin || currentRole.it_admin ? { ...workflowRouteObject } : null,
      currentRole.admin ? { ...settingsRouteObject } : null,
      currentRole.admin || currentRole.it_admin || currentRole.finance_admin || currentRole.procurement_admin
       ? { ...auditLogsRouteObject }
       : null,
    ];
    saasMenu = saasMenu.filter(item => item);
    return saasMenu;
  }

  getPermissionResourceForSaas() {
    const resourceList = this.authenticationService.getSaasResourceList();
    return resourceList;
  }
}
