// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const BASE_URL = 'http://cloud-dev.prod.tangoe.com';

export const environment = {
  production: false,
  isLocal: false,
  instanceIdentifier: 'dev',
  appDrawer: {
    enabled: true,
    excludedApps: ['Cloud'],
  },

  helpUrls: {
    default: 'https://docs.tangoe.com/cloud/onlinehelp/index.htm',
  },

  masterDataUrl: BASE_URL + '/MasterDataService/api/MasterDataService/v1',
  expenseDataUrl: BASE_URL + '/ExpenseService/api/ExpenseService/v1',
  billImportUrl: BASE_URL + '/ExpenseService/api/BillImportService/v1',
  dynamicListUrl: BASE_URL + '/DynamicListService/api/DynamicListService/v1',
  enterpriseDataUrl: BASE_URL + '/EnterpriseDataService/api/EnterpriseDataService/v1',
  extEnterpriseDataUrl: BASE_URL + '/EnterpriseDataService/api/ExtEnterpriseDataService/v1',
  securityServiceUrl: BASE_URL + '/SecurityService/api/SecurityService/v1',
  assuranceDataUrl: BASE_URL + '/AssuranceService/api/AssuranceService/v1',
  workflowServiceUrl: BASE_URL + '/WorkflowService/api/WorkflowService/v1',
  budgetUrl: BASE_URL + '/BudgetingService/api/BudgetingService/v1',

  sisenseUrl: 'https://nadevgvbi03.rdsb.aws.tangoe.com/',

  ssoUrl: 'https://tangoe.devb-auth.rdsb.aws.tangoe.com',
  ssoLoginUrl: 'https://tangoe.devb-auth.rdsb.aws.tangoe.com/api/sso/cloud',
  ssoLogoutUrl: 'https://tangoe.devb-auth.rdsb.aws.tangoe.com/logout',

  saasIframeUrl: 'https://app-tangoe.zluri.dev',
  saasSamlConnectionName: 'TangoeNewDevSAML',

  iaasOptimizationUrl: 'https://development-tangoe-api.cloudadmin.io',
  iaasOptimizationIframeUrl: 'https://tangoe-development.cloudadmin.io',
  cloudAdminHealthUrl: 'https://tangoe-development.cloudadmin.io/health',

  privacyPolicyUrl: 'https://www.tangoe.com/privacy-policy/',

  walkmeUrl:
    'https://cdn.walkme.com/users/ba3b6a2bca2d422b9a7138603e7db3aa/test/walkme_ba3b6a2bca2d422b9a7138603e7db3aa_https.js',

  applicationLogoutUrl: BASE_URL + '/cloud/logout.htm',
  applicationTenantSwitchUrl: BASE_URL + '/cloud/switch_tenant_action.htm',

  revision: 'b36bce2b196d69d371e3dfb8b240e45e27289579',
  buildDate: '2024-10-10 ',

  oldUiBaseUrl: BASE_URL + '/cloud',

  x_tngo_tenant: '',
  x_tngo_user: '',
  x_tngo_login_key: '',
  x_tngo_direct_cookie: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error' ;  // Included with Angular CLI.
